import React from 'react';
import './Sector.css';
import { Container } from 'react-bootstrap';
import MainHeading from 'ui/MainHeading';

import SectorSwipe from './SwiperSectors/SectorSwipe';
const Sector = ({ data }) => {
  return (
    <div className="Sector">
      <Container>
        <div className="sectorContainer">
          <MainHeading
            title={data?.sector?.title}
            description={data?.sector?.description}
          />
          <SectorSwipe slides={data?.banners} />
        </div>
      </Container>
    </div>
  );
};
export default Sector;

import Hero from 'components/HomeComponents/Hero/Hero';
import HVideo from 'components/HomeComponents/HomeVideo/HVideo';
import Innovation from 'components/HomeComponents/Innovation/Innovation';
import Sector from 'components/HomeComponents/Sector/Sector';
import Services from 'components/HomeComponents/Services/Services';
import Technology from 'components/HomeComponents/Technology/Technology';
import { LanguageContext } from 'context/LanguageContext';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Element } from 'react-scroll';
import { useFetchBannersQuery } from '../../redux/slices/BannerSlice';
import LoadingPage from 'pages/LoadingPage';
import { useFetchStudioQuery } from '../../redux/slices/StudioSlice';
import { useFetchAboutQuery } from '../../redux/slices/AboutSlice';
import { useFetchServicesQuery } from '../../redux/slices/ServicesSliece';
import { useFetchSectorQuery } from '../../redux/slices/SectorSection';
import { useFetchTechnologiesQuery } from '../../redux/slices/TechnologiesSlice';
import MetaTag from 'utils/MetaTag';
import { Spinner } from 'react-bootstrap';
import { motion } from 'framer-motion';
import AboutUs from 'components/HomeComponents/aboutUs/AboutUs';

const Home = () => {
  const path = useLocation();
  useEffect(() => {
    if (path.pathname === '/') {
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
  }, []);
  const { lang } = useContext(LanguageContext);
  const { data: banners, error, isLoading } = useFetchBannersQuery(lang);
  const {
    data: video,
    error: errorVideo,
    isLoading: loadingVideo,
  } = useFetchStudioQuery(lang);

  const {
    data: about,
    error: about_error,
    isLoading: loadingAbout,
  } = useFetchAboutQuery(lang);
  const {
    data: services,
    error: errorServices,
    isLoading: loadingServices,
  } = useFetchServicesQuery(lang);
  const {
    data: sector,
    error: errorSector,
    isLoading: loadingSector,
  } = useFetchSectorQuery(lang);

  const {
    data: technologies,
    error: errorTechnologies,
    isLoading: loadingTechnologies,
  } = useFetchTechnologiesQuery(lang);

  if (isLoading && path.pathname === '/') {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }
  if (error) {
    return <div>something went wrong</div>;
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <MetaTag />
      {!error && <Hero banners={banners} />}
      <Element
        name="About"
        id="aboutSection"
        style={{
          textAlign: 'center',
          minHeight: '40vh',
          margin: 'auto',
        }}
      >
        {loadingAbout ? (
          <Spinner animation="grow" className="my-5" />
        ) : (
          !about_error && <Innovation about={about} />
        )}
      </Element>
      {loadingVideo ? (
        <Spinner animation="grow" className="my-5" />
      ) : (
        !errorVideo && <HVideo media={video?.data} />
      )}
      <Element
        name="Service"
        id="seriveSection"
        style={{
          textAlign: 'center',
          minHeight: '40vh',
          margin: 'auto',
        }}
      >
        {loadingServices ? (
          <Spinner animation="grow" className="my-5" />
        ) : (
          !errorServices && <Services services={services} />
        )}
      </Element>
      {loadingSector ? (
        <Spinner animation="grow" className="my-5" />
      ) : (
        !errorSector && <Sector data={sector?.data} />
      )}
      {loadingTechnologies ? (
        <Spinner animation="grow" className="my-5" />
      ) : (
        !errorTechnologies && <Technology imgs={technologies} />
      )}
    </motion.div>
  );  
};

export default Home;

import { configureStore } from '@reduxjs/toolkit';
import { bannersApi } from './slices/BannerSlice';
import { aboutApi } from './slices/AboutSlice';
import { studioApi } from './slices/StudioSlice';
import { servicesApi } from './slices/ServicesSliece';
import { sectorSectionApi } from './slices/SectorSection';
import { technologiesApi } from './slices/TechnologiesSlice';

const store = configureStore({
  reducer: {
    [bannersApi.reducerPath]: bannersApi.reducer,
    [aboutApi.reducerPath]: aboutApi.reducer,
    [studioApi.reducerPath]: studioApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [sectorSectionApi.reducerPath]: sectorSectionApi.reducer,
    [technologiesApi.reducerPath]: technologiesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(bannersApi.middleware)
      .concat(aboutApi.middleware)
      .concat(studioApi.middleware)
      .concat(servicesApi.middleware)
      .concat(sectorSectionApi.middleware)
      .concat(technologiesApi.middleware),
});

export default store;

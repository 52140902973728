import React from 'react';
import { Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
const TechnoCard = ({ t }) => {
  return (
    <Col lg={'3'} md={'4'} sm={'6'} xs={'6'}>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
        className="TechnoCard"
      >
        <img src={t} alt="technology" />
      </motion.div>
    </Col>
  );
};

export default TechnoCard;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const aboutApi = createApi({
  reducerPath: 'aboutApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchAbout: builder.query({
      query: (lang) => ({
        url: 'why/integration',
        headers: {
          lang: lang,
        },
      }),
    }),
  }),
});

export const { useFetchAboutQuery } = aboutApi;

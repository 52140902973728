import React, { useContext } from 'react';
import './SectorSwipe.css';
import { LanguageContext } from 'context/LanguageContext';
const SectorCard = ({ item }) => {
  const { lang } = useContext(LanguageContext);
  return (
    <div
      className={`SectorCard ${lang === 'ar' ? 'SectorCard_ar' : ''}`}
      style={{ backgroundImage: `url(${item?.background})` }}
    >
      <div className="sectorContent">
        <div className="sectorIcon">
          <img src={item?.image} alt={`icon ${item?.title}`} />
        </div>
        <div className="sectorTitle">
          <h4>{item?.title}</h4>
        </div>
        <div className="sectorDes">
          <p>{item?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default SectorCard;

import React, { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { LanguageContext } from 'context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const HeroSwiper = ({ slides }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const card = e.currentTarget; // استخدم e.currentTarget بدلاً من ref
    const x = e.pageX - card.offsetLeft;
    const y = e.pageY - card.offsetTop;

    setMousePosition({ x, y });
  };

  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  return (
    <Swiper
      key={lang}
      modules={[Autoplay, Pagination, A11y, Scrollbar]}
      pagination={{ clickable: true }}
      loop={true}
      scrollbar={{ draggable: true }}
      // autoplay={{ delay: 6000 }}
      className="hero-swiper"
    >
      {slides?.map((slide, index) => (
        <SwiperSlide
          key={slide?.id}
          style={{
            backgroundImage: `url(${slide.image_path})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            position: 'relative',
            // transform: 'scaleX(-1)',
          }}
        >
          {/* <Container> */}
          <div
            className={`slide-content ${lang === 'ar' ? 'slide-content-ar' : ''}`}
            onMouseMove={handleMouseMove}
            style={{
              '--left': `${mousePosition.x}px`, // استخدام حالة الماوس
              '--top': `${mousePosition.y}px`,
            }}
            // style={{
            //   transform: 'scaleX(-1)',
            // }}
          >
            <h2>{slide.title}</h2>
            <p>{slide.description}</p>
            <Link to={'/contact-us'}>{t('get_in_touch')}</Link>
          </div>
          {/* </Container> */}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroSwiper;

import React from 'react';
import { Row } from 'react-bootstrap';
import TechnoCard from './TechnoCard';
import './TechnoList.css';
const TechnoList = ({ imgs }) => {
  return (
    <div className="technolist">
      <Row>
        {imgs?.map((item) => (
          <TechnoCard t={item?.image} key={item?.id}/>
        ))}
      </Row>
    </div>
  );
};

export default TechnoList;

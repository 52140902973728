import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchServices: builder.query({
      query: (lang) => ({
        url: 'services',
        headers: {
          lang: lang,
        },
      }),
      staleTime: 1000 * 60 * 5,
    }),
  }),
});

export const { useFetchServicesQuery } = servicesApi;

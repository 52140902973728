import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './ContactForm.css';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { notify } from 'utils/notify';
import { LanguageContext } from 'context/LanguageContext';

const ContactForm = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  // إعداد Formik
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('name_required')),
      email: Yup.string()
        .email(t('email_invalid'))
        .required(t('email_required')),
      message: Yup.string().required(t('message_required')),
    }),
    onSubmit: async (values) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}contacts`,
          values,
        );
        if (res.status === 200 && res.data.status === 1) {
          if (lang === 'ar') {
            notify('تم إرسال البيانات بنجاح', 'success');
            formik.resetForm();
          } else {
            notify('Message sent successfully', 'success');
            formik.resetForm();
          }
        }
      } catch (error) {
        if (lang === 'ar') {
          notify('لم يتم ارسال رسالتك اعد المحاولة', 'error');
          formik.resetForm();
        } else {
          notify('Faild to send message try again', 'success');
          formik.resetForm();
        }
      }
    },
  });
  // const formik = useFormik({
  //   initialValues: {
  //     name: '',
  //     email: '',
  //     message: '',
  //   },
  //   validationSchema: Yup.object({
  //     name: Yup.string().required('Name is required'),
  //     email: Yup.string()
  //       .email('Invalid email address')
  //       .required('Email is required'),
  //     message: Yup.string().required('Message is required'),
  //   }),
  //   onSubmit: (values) => {
  //     console.log('Form Data:', values);
  //     // إرسال البيانات هنا أو التعامل مع الـ API
  //   },
  // });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} className="contact-form">
        <div className="form-field">
          <label htmlFor="name">{t('name')}</label>
          <input
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {/* {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null} */}

          <div className="error">
            {formik.touched.name && formik.errors.name && formik.errors.name}
          </div>
        </div>

        <div className="form-field">
          <label htmlFor="email">{t('email')}</label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <div className="error">
            {formik.touched.email && formik.errors.email && formik.errors.email}
          </div>
          {/* {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null} */}
        </div>

        <div className="form-field">
          <label htmlFor="message">{t('message')}</label>
          <textarea
            id="message"
            name="message"
            rows="8"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          ></textarea>
          <div className="error">
            {formik.touched.message &&
              formik.errors.message &&
              formik.errors.message}
          </div>
          {/* {formik.touched.message && formik.errors.message ? (
            <div className="error">{formik.errors.message}</div>
          ) : null} */}
        </div>

        <button type="submit" className="submit-button">
          {t('send_my_message')}
        </button>
      </form>
    </Container>
  );
};

export default ContactForm;

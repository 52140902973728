import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const sectorSectionApi = createApi({
  reducerPath: "sectorSectionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchSector: builder.query({
      query: (lang) => ({
        url: "sectors",
        headers: {
          lang: lang,
        },
      }),
    }),
  }),
});

export const { useFetchSectorQuery } = sectorSectionApi;

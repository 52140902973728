import { LanguageContext } from 'context/LanguageContext';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const MetaTag = () => {
  const { lang } = useContext(LanguageContext);
  const path = useLocation();
  const metaHome = {
    en: {
      title: 'Innovation Integration | Digital Transformation Solutions',
      description:
        'Explore our advanced digital transformation solutions at Innovation Integration, where we empower businesses to succeed with AI, cloud services, and customized technology solutions.',
    },
    ar: {
      title: 'تكامل الإبتكار | حلول التحول الرقمي',
      description:
        'اكتشف حلول التحول الرقمي المتقدمة لدينا في تكامل الإبتكار، حيث نُمكّن الشركات من النجاح باستخدام الذكاء الاصطناعي والخدمات السحابية والحلول التقنية المخصصة.',
    },
  };
  const metaAboutUs = {
    en: {
      title: 'About Us | Innovation Integration',
      description:
        'Learn about Innovation Integration, our mission to lead in technological innovation, and how we help businesses through customized digital transformation and cloud solutions.',
    },
    ar: {
      title: 'من نحن | تكامل الإبتكار',
      description:
        'تعرّف على تكامل الإبتكار ورؤيتنا للريادة في الابتكار التكنولوجي، وكيف نساعد الشركات من خلال حلول التحول الرقمي والخدمات السحابية المخصصة.',
    },
  };
  const metaOurServices = {
    en: {
      title: 'Our Services | Innovation Integration',
      description:
        'Discover our wide range of services at Innovation Integration, including cloud services, AI solutions, and customized technology services to meet your unique business needs.',
    },
    ar: {
      title: 'خدماتنا | تكامل الإبتكار',
      description:
        'اكتشف مجموعة واسعة من الخدمات التي نقدمها في تكامل الإبتكار، بما في ذلك الخدمات السحابية وحلول الذكاء الاصطناعي والخدمات التقنية المخصصة لتلبية احتياجات عملك الفريدة.',
    },
  };
  const metaContactUs = {
    en: {
      title: 'Contact Us | Innovation Integration',
      description:
        'Get in touch with Innovation Integration for inquiries, support, or project discussions. Our team is ready to help you with all your digital transformation needs.',
    },
    ar: {
      title: 'تواصل معنا | تكامل الإبتكار',
      description:
        'تواصل مع تكامل الإبتكار للاستفسارات أو الدعم أو مناقشة المشاريع. فريقنا جاهز لمساعدتك في جميع احتياجاتك للتحول الرقمي.',
    },
  };
  return (
    <>
      {path?.pathname === '/' ? (
        <Helmet>
          <title>{metaHome[lang].title}</title>
          <meta name="description" content={metaHome[lang].description} />
        </Helmet>
      ) : path?.pathname === '/about-us' ? (
        <Helmet>
          <title>{metaAboutUs[lang].title}</title>
          <meta name="description" content={metaAboutUs[lang].description} />
        </Helmet>
      ) : path?.pathname === '/our-services' ? (
        <Helmet>
          <title>{metaOurServices[lang].title}</title>
          <meta
            name="description"
            content={metaOurServices[lang].description}
          />
        </Helmet>
      ) : path?.pathname === '/contact-us' ? (
        <Helmet>
          <title>{metaContactUs[lang].title}</title>
          <meta name="description" content={metaContactUs[lang].description} />
        </Helmet>
      ) : (
        ''
      )}
    </>
  );
};

export default MetaTag;

import React, { useContext, useState } from 'react';
import './Faqs.css';
import ServiceBody from '../ServiceBody/ServiceBody';
import { LanguageContext } from 'context/LanguageContext';
import { motion } from 'framer-motion';
const Faqs = ({ data }) => {
  const [activeFaq, setActiveFaq] = useState(0);
  const { lang } = useContext(LanguageContext);
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      exit={{ opacity: 0, y: -100 }}
      style={{ overflow: 'hidden' }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.9 }}
      className="Faqs"
    >
      {data?.map((item, index) => (
        <div className="faq" key={item?.id}>
          <div
            className="faqTitle"
            onClick={() => setActiveFaq(activeFaq === index ? null : index)}
          >
            {item?.title}
            <span
              className={`${activeFaq === index ? 'rotate' : ''} ${lang === 'ar' ? 'rotate_ar' : ''}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <circle
                  cx="16.5657"
                  cy="15.9837"
                  r="14.8187"
                  stroke="#B8B8B8"
                  strokeWidth="1.91209"
                />
                <path
                  d="M12.2636 23.1541L23.7361 16.4618L12.2636 9.76953"
                  stroke="white"
                  strokeWidth="1.91209"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
          <div
            className={`faqDescription ${
              activeFaq === index ? 'showDescription' : ''
            }`}
          >
            <ServiceBody item={item} />
          </div>
        </div>
      ))}
    </motion.div>
  );
};

export default Faqs;

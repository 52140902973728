import React from 'react';
const ServiceCard = ({ data }) => {
  return (
    <div className="ServiceCard">
      <div className="serviceIcon">
        <div className="iconCard">
          <img src={data?.image} alt="serviceCardicon" />
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="25"
          viewBox="0 0 30 25"
          fill="none"
        >
          <path
            d="M0.134766 0.827637H29.8019L15.653 12.1333L0.134766 24.5331V0.827637Z"
            fill="#FFF5F4"
          />
        </svg>
      </div>
      <div className="serviceTitle">
        <h4>{data?.title}</h4>
      </div>
      <div className="serviceDesc">
        <p>{data?.description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;

import React, { useContext } from 'react';
import './MainHeading.css';
import { motion } from 'framer-motion';
import { LanguageContext } from 'context/LanguageContext';
const MainHeading = (props) => {
  const {lang} = useContext(LanguageContext);
  return (
    <motion.div
      initial={{ opacity: 0, x: lang === 'ar' ? 100 : -100 }}
      exit={{ opacity: 0, x: lang === 'ar' ? -100 : 100 }}
      style={{ overflow: 'hidden' }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.9 }}
      className="txtinn"
    >
      <h2>{props.title}</h2>
      <p>{props.description}</p>
    </motion.div>
  );
};

export default MainHeading;

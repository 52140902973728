import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { motion } from 'framer-motion';
import SectorCard from './SectorCard';
import { LanguageContext } from 'context/LanguageContext';

const SectorSwipe = ({ slides }) => {
  const { lang } = useContext(LanguageContext);
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      exit={{ opacity: 0, y: -100 }}
      // style={{ overflow: 'hidden' }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.9 }}
      className="sector-swipe-container"
    >
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        navigation={{
          nextEl: '.custom-next',
          prevEl: '.custom-prev',
        }}
        loop={true}
      >
        {slides?.map((item) => (
          <SwiperSlide key={item?.id}>
            <div>
              <SectorCard item={item} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <button className="custom-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M4.68788 15H24.6876"
            stroke="#9C9C9C"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M11.7625 22.075C11.7625 18.4379 8.55926 15 4.68739 15"
            stroke="#9C9C9C"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M11.7625 7.92494C11.7625 11.5621 8.55926 15 4.68739 15"
            stroke="#9C9C9C"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      </button>
      <button className="custom-next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M25.3121 15H5.31238"
            stroke="#9C9C9C"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M18.2375 22.075C18.2375 18.4379 21.4408 15 25.3127 15"
            stroke="#9C9C9C"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M18.2375 7.92494C18.2375 11.5621 21.4408 15 25.3127 15"
            stroke="#9C9C9C"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      </button>
    </motion.div>
  );
};

export default SectorSwipe;

import React from 'react';
import './AboutUs.css';
import MainHeading from 'ui/MainHeading';
import { useTranslation } from 'react-i18next';
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className='about-us'>
      <MainHeading title={t('ABOUTUS')} description={t('about_des')} />
    </div>
  );
};

export default AboutUs;

import React from 'react';
import './HVideo.css';
import { motion } from 'framer-motion';
// import hero2 from 'assets/images/video.gif';
const HVideo = ({ media }) => {
  return (
    <motion.div
      initial={{ opacity: 0.8, scale: 0.6 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.7 }}
      className="HVideo"
    >
      {media?.type_file === 'image' ? (
        <img src={media?.image} alt="Background Animation" />
      ) : (
        <video muted autoPlay loop playsInline>
          <source src={media?.image} type="video/mp4"></source>
        </video>
      )}
    </motion.div>
  );
};

export default HVideo;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bannersApi = createApi({
  reducerPath: 'bannersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchBanners: builder.query({
      query: (lang) => ({
        url: 'banners',
        headers: {
          lang: lang,
        },
      }),
      staleTime: 1000 * 60 * 5,
    }),
  }),
});

export const { useFetchBannersQuery } = bannersApi;

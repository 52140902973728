import React from 'react';
import './ServiceBody.css';
import ListService from './listService/ListService';
const ServiceBody = ({ item }) => {
  return (
    <div className="ServiceBody">
      <div
        className="topService"
        style={{ backgroundImage: `url(${item?.background})` }}
      >
        <p>
          <span>{item?.title}</span> {item?.description}
        </p>
      </div>
      <ListService list={item?.items}/>
    </div>
  );
};

export default ServiceBody;

import React from 'react';
import './Services.css';
import { Container } from 'react-bootstrap';
import MainHeading from 'ui/MainHeading';
import Faqs from './faqs/Faqs';
import { useTranslation } from 'react-i18next';
const Services = ({ services }) => {
  const { t } = useTranslation();
   return (
    <div className="Services">
      <Container>
        <div className="serviceCOntainer">
          <MainHeading
            title={t('ourServices')}
            description={t('customSolutions')}
          />
          <Faqs data={services?.data} />
        </div>
      </Container>
    </div>
  );
};

export default Services;

import React from 'react';
import './ListService.css';
import { Col, Row } from 'react-bootstrap';
import ServiceCard from './ServiceCard';
const ListService = ({ list }) => {
  return (
    <Row>
      {list?.map((item) => (
        <Col lg={4} key={item?.id}>
          <ServiceCard data={item}/>
        </Col>
      ))}
    </Row>
  );
};

export default ListService;

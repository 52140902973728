import React from 'react';
import './Tecnology.css';
import { Container } from 'react-bootstrap';
import MainHeading from 'ui/MainHeading';
import TechnoList from './ListTechno/TechnoList';
import { useTranslation } from 'react-i18next';
const Technology = ({ imgs }) => {
  const { t } = useTranslation();
  return (
    <div className="Technology">
      <Container>
        <MainHeading title={t('technology')} />
        <div className="wave">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1246"
            height="628"
            viewBox="0 0 1246 628"
            fill="none"
          >
            <path
              d="M1042.27 272C879.17 250.266 820.133 107.398 579.983 116C339.833 124.602 229.764 -0.000213757 48.6506 -0.000241473C-132.463 -0.000269189 -194 95.9997 -194 95.9997L-194 628L1246 628L1246 245C1246 245 1205.37 293.733 1042.27 272Z"
              fill="url(#paint0_linear_2019_664)"
              fillOpacity="0.51"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2019_664"
                x1="563.524"
                y1="-0.000242444"
                x2="563.524"
                y2="435.905"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#61B3FF" />
                <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <TechnoList imgs={imgs?.data} />
      </Container>
    </div>
  );
};

export default Technology;

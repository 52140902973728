import React from 'react';
import './HeroContact.css';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const HeroContact = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="HeroContact"
    >
      <Container>
        <h1>{t('contact_us')}</h1>
      </Container>
    </motion.div>
  );
};

export default HeroContact;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InnovationCard from './InnovationCard';

const InnovationList = ({ aboutCards }) => {
  return (
    <Row>
      {aboutCards?.map((card) => (
        <Col lg={3} md={6} sm={6} xs={12} className="mb-3" key={card?.id}>
          <InnovationCard data={card}/>
        </Col>
      ))}
    </Row>
  );
};

export default InnovationList;

import React, { useContext } from 'react';
import './Innovation.css';
import { Container } from 'react-bootstrap';
import InnovationList from './innovationCards/InnovationList';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { LanguageContext } from 'context/LanguageContext';
import AboutUs from '../aboutUs/AboutUs';
const Innovation = ({ about }) => {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  return (
    <motion.div
      initial={{ opacity: 0, x: lang === 'ar' ? 100 : -100 }}
      exit={{ opacity: 0, x: lang === 'ar' ? -100 : 100 }}
      style={{ overflow: 'hidden' }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.9 }}
      className="innovation"
    >
      <Container>
        <AboutUs />

        <div className="innpvationContent">
          <div className="txtinn">
            <h2>{t('whyIntegrateInnovation')}</h2>
          </div>
          <InnovationList aboutCards={about?.data} />
        </div>
      </Container>
    </motion.div>
  );
};

export default Innovation;

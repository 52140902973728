import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const technologiesApi = createApi({
  reducerPath: 'technologiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    fetchTechnologies: builder.query({
      query: (lang) => ({
        url: 'technology',
        headers: {
          lang: lang,
        },
      }),
    }),
  }),
});

export const { useFetchTechnologiesQuery } = technologiesApi;

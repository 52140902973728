import React from 'react';
import './ContactHeader.css';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const ContactHeader = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{ opacity: 0, y: 100 }}
      transition={{ duration: 2 }}
      className="ContactHeader"
    >
      <Container>
        <h2>{t('contactUs')}</h2>
        <p>{t('contactDescription')}</p>
      </Container>
    </motion.div>
  );
};

export default ContactHeader;

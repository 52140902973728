import ContactHeader from 'components/ContactComponents/ContactHeader/ContactHeader';
import HeroContact from 'components/ContactComponents/HeroContact/HeroContact';
import React, { useContext, useEffect } from 'react';
import './Contact.css';
import ContactInfo from 'components/ContactComponents/ContactInfo/ContactInfo';
import ContactForm from 'components/ContactComponents/ContactForm/ContactForm';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from 'context/LanguageContext';
import { motion } from 'framer-motion';
import ContactLocation from 'components/ContactComponents/ContactLocation/ContactLocation';

const ContactUs = () => {
  const { lang } = useContext(LanguageContext);
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const metaContactUs = {
    en: {
      title: 'Contact Us | Innovation Integration',
      description:
        'Get in touch with Innovation Integration for inquiries, support, or project discussions. Our team is ready to help you with all your digital transformation needs.',
    },
    ar: {
      title: 'تواصل معنا | تكامل الإبتكار',
      description:
        'تواصل مع تكامل الإبتكار للاستفسارات أو الدعم أو مناقشة المشاريع. فريقنا جاهز لمساعدتك في جميع احتياجاتك للتحول الرقمي.',
    },
  };
  return (
    <div className="contact">
      <Helmet>
        <title>{metaContactUs[lang].title}</title>
        <meta name="description" content={metaContactUs[lang].description} />
      </Helmet>
      <HeroContact />
      <ContactHeader />
      <ContactInfo />
      <ContactForm />
      <ContactLocation/>
    </div>
  );
};

export default ContactUs;

import MainLayout from 'components/MainLayout';
import { LanguageContext } from 'context/LanguageContext';
import i18n from 'i18n';
import ContactUs from 'pages/Contact/ContactUs';
import Home from 'pages/Home/Home';
import LoadingPage from 'pages/LoadingPage';
import NotFound from 'pages/not-found/NotFound';
import { Suspense, useContext, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MetaTag from 'utils/MetaTag';

function App() {
  const { lang } = useContext(LanguageContext);
  const langLocal = localStorage.getItem('lang');
  useEffect(() => {
    if (langLocal && ['en', 'ar'].includes(langLocal)) {
      i18n.changeLanguage(langLocal); // Set the language from local storage
    }
  }, [langLocal]);
  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<LoadingPage />}>
              <Home />
            </Suspense>
          ),
        },

        {
          path: 'about-us',
          element: (
            <Suspense fallback={<LoadingPage />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: 'our-services',
          element: (
            <Suspense fallback={<LoadingPage />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: 'contact-us',
          element: (
            <Suspense fallback={<LoadingPage />}>
              <ContactUs />
            </Suspense>
          ),
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ]);
  return (
    <div
      className="App"
      style={{
        direction: `${lang === 'ar' ? 'rtl' : 'ltr'}`,
        fontFamily: `${
          lang === 'ar' ? '"Cairo", sans-serif' : '"Archivo", sans-serif'
        }`,
      }}
    >
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import React from 'react';
import './Hero.css';
import HeroSwiper from './HeroSwiper';

const Hero = ({ banners }) => {
  return (
    <div className="Hero">
      <HeroSwiper slides={banners?.data} />
    </div>
  );
};

export default Hero;
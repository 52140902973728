import React from 'react';
import './ContactLocation.css';
import { Container } from 'react-bootstrap';
const ContactLocation = () => {
  return (
    <div className="ContactLocation">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2289.9729341123048!2d46.887656842047946!3d24.825084791512744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2e4e73ccb73d4b%3A0xc4942546579ea5c7!2z2LfYsdmK2YIg2K7YsdmK2LXYjCDYp9mE2LHZitin2LYg2KfZhNiz2LnZiNiv2YrYqQ!5e0!3m2!1sar!2seg!4v1731160371272!5m2!1sar!2seg"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default ContactLocation;
